import { useState, useEffect } from "react";
import {
	Paper,
	Stepper,
	Step,
	StepLabel,
	Typography,
	CircularProgress,
	Divider,
	Button,
	CssBaseline,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

import { commerce } from "../../../lib/commerce";
import AddressForm from "../AddressForm";
import PaymentForm from "../PaymentForm";

import useStyles from "./styles";

const steps = ["Στοιχεία αποστολής", "Λεπτομέρειες πληρωμής"];

const Checkout = ({ cart, onCaptureCheckout, order, error }) => {
	const [checkoutToken, setCheckoutToken] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [shippingData, setShippingData] = useState({});
	const [isFinished, setIsFinished] = useState(false);

	const classes = useStyles();
	const history = useHistory();

	useEffect(() => {
		if (cart.id) {
			const generateToken = async () => {
				try {
					const token = await commerce.checkout.generateToken(
						cart.id,
						{ type: "cart" }
					);
					setCheckoutToken(token);
				} catch (error) {
					// if (activeStep !== steps.length) history.push("/");
					history.push("/");
				}
			};

			generateToken();
		}
	}, [cart]);

	const nextStep = () =>
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	const backStep = () =>
		setActiveStep((prevActiveStep) => prevActiveStep - 1);

	const next = (data) => {
		setShippingData(data);
		nextStep();
	};

	const timeout = () => {
		setTimeout(() => {
			setIsFinished(true);
		}, 3000);
	};

	let Confirmation = () =>
		order.customer ? (
			<>
				<div>
					<Typography variant="h5">
						Ευχαριστούμε για την αγορά σας,{" "}
						{order.customer.firstname} {order.customer.lastname}!
					</Typography>
					<Divider className={classes.divider} />
					<Typography variant="subtitle2">
						Order ref: {order.customer_reference}
					</Typography>
				</div>
				<br />
				<Button
					component={Link}
					variant="outlined"
					type="button"
					to="/"
				>
					Back to home
				</Button>
			</>
		) : isFinished ? (
			<>
				<div>
					<Typography variant="h5">
						Ευχαριστούμε για την αγορά σας!
					</Typography>
					<Divider className={classes.divider} />
				</div>
				<br />
				<Button
					component={Link}
					variant="outlined"
					type="button"
					to="/"
				>
					ΠΙΣΩ ΣΤΗΝ ΑΡΧΙΚΗ
				</Button>
			</>
		) : (
			<div className={classes.spinner}>
				<CircularProgress />
			</div>
		);

	if (error) {
		<>
			<Typography variant="h5">Error: {error}</Typography>
			<br />
			<Button component={Link} variant="outlined" type="button" to="/">
				ΠΙΣΩ ΣΤΗΝ ΑΡΧΙΚΗ
			</Button>
		</>;
	}

	const Form = () =>
		activeStep === 0 ? (
			<AddressForm
				cart={cart}
				checkoutToken={checkoutToken}
				nextStep={nextStep}
				setShippingData={setShippingData}
				next={next}
			/>
		) : (
			<PaymentForm
				checkoutToken={checkoutToken}
				nextStep={nextStep}
				backStep={backStep}
				shippingData={shippingData}
				onCaptureCheckout={onCaptureCheckout}
				timeout={timeout}
			/>
		);

	return (
		<>
			<CssBaseline />
			<div className={classes.toolbar} />
			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<Typography variant="h4" align="center">
						Checkout
					</Typography>
					<Stepper
						activeStep={activeStep}
						className={classes.stepper}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					{activeStep === steps.length ? (
						<Confirmation />
					) : (
						checkoutToken && <Form />
					)}
				</Paper>
			</main>
		</>
	);
};

export default Checkout;
